import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';
import { useState } from "react";

const UPLOAD_FILE_URL = '/upsingle';
const ADD_EVENT_URL = '/events/add';

function AddEvent() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
  
  const eventSubmit = async (e) => {
    e.preventDefault();

    const date = eventDate;
    const newEvent = {title, date, location, description, author: authDecode.user.id, };
    
    if(file){
      const formData = new FormData();
      const filename = Date.now() + file.name;
      
      formData.append("name", filename);
      formData.append("file", file);
      newEvent.picture = filename;

      try{
        await axiosPrivate.post(UPLOAD_FILE_URL, formData);
      }catch(err){
        console.log(err);
      }
    }
    
    try {
      await axiosPrivate.post(ADD_EVENT_URL, newEvent).then((response)=>{
        console.log(response);
        navigate('/events');
      });   
    }catch (error) {
      console.log(error);  
    }
  };
  return (
    <div className="col-lg-8 mb-3">
    <div className="section-title mb-3">
      <h4 className="m-0 text-uppercase font-weight-bold">Add New Event</h4>
    </div>
    {file && (
      <img className="img-fluid" src={URL.createObjectURL(file)} alt="" />
    )}
    <form onSubmit={eventSubmit}>
      <div className='form-group'>
          <label htmlFor='fileInput'><i className='writeIcon fas fa-plus'></i> Add Event Image</label>
          <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
      </div>
      <div class="form-group">
        <input type='text' placeholder='Event Name' className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
      </div>
      <div className="form-group">
        <input type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
      </div>                
      <div class="form-group">
        <input type='text' placeholder='Location' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
      </div>
      <div class="form-group">
        <textarea placeholder='Event Details ...' type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
      </div>
      <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!file || !title || !eventDate || !location || !description} type="submit">Submit</button>
      &nbsp;&nbsp;&nbsp;  
      <Link className="btn btn-primary font-weight-semi-bold px-4" to='/events'>Cancel</Link>  
    </form>
  </div>
  )
}

export default AddEvent
