import { Link } from "react-router-dom";
import './Components.scss';

const Card = ({ PF, item }) => {

  const image = item.images.split(','); 

  return (
    <div className="col-lg-3 mb-2">
      
        <div className="card">
          <Link className="link" to={`/shop/product/${item.id}`}>
            <div className="image">
              <img src={ PF + image[0] } alt="" className="img-fluid mainImg" />
              <img src={ PF + image[1] } alt="" className="img-fluid secondImg" />
            </div>
            <h2 className="p-2">{item?.title}</h2>
          </Link>
          <div className="prices p-2">
            <h3>{item?.salePrice>0 && item?.price.toLocaleString('en')}</h3>
            <h3>{item?.salePrice>0 ? item.salePrice.toLocaleString('en') : item.price.toLocaleString('en')}/-</h3>
          </div>
        </div>

    </div>
  );
};

export default Card;
