import { AuthProvider } from './Assets/AuthProvider';
import { Route, Routes } from 'react-router-dom';

//Components
import PersistLogin from './Components/PersistLogin';
import ScrollToTop from './Components/ScrollToTop';
import RequireAuth from './Components/RequireAuth';
import BottomBar from './Components/BottomBar';
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';

// Modules
import Membership from './Modules/Membership';
import Articles from './Modules/Articles';
import Library from './Modules/Library';
import Profiles from './Modules/Profiles';
import Gallery from './Modules/Gallery';
import Home from './Modules/Home/Home';
import Events from './Modules/Events';
import About from './Modules/About';
import Admin from './Modules/Admin';
import User from './Modules/User';
import News from './Modules/News';
import Shop from './Modules/Shop';

import PageNotFound from './Pages/PageNotFound';
import ComingSoon from './Pages/ComingSoon';
import Register from './Pages/Register';
import Login from './Pages/Login';


function App() {

  let IMAGE_URL = 'http://localhost:3003';
  if(process.env.NODE_ENV === 'production'){ IMAGE_URL = 'https://www.budonian.com/app'; }
  
  const PF = `${IMAGE_URL}/images/`;

  return (
    <AuthProvider>
      <div className="App">
        <ScrollToTop />
        <Navbar PF={PF}/>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route exact path='/' element={<Home PF={PF} />}/>
              <Route exact path='/articles/*' element={<Articles PF={PF} />} />
              <Route exact path='/library/*' element={<Library PF={PF} />} />
              <Route exact path='/profiles/*' element={<Profiles PF={PF} />} />
              <Route exact path='/gallery/*' element={<Gallery PF={PF} />} />
              <Route exact path='/events/*' element={<Events PF={PF} />} />
              <Route exact path='/about/*' element={<About PF={PF} />} />
              <Route exact path='/membership' element={<Membership />} />
              <Route exact path='/news/*' element={<News PF={PF} />} />
              <Route exact path='/register' element={<Register />} />               
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/shop/*' element={<Shop PF={PF} />} />
              <Route exact path='/soon' element={<ComingSoon PF={PF} />}/>
              <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
                <Route exact path='/user/*' element={<User PF={PF} />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[4, 5]} />}>
                <Route exact path='/admin/*' element={<Admin PF={PF} />} />
              </Route>
              <Route exact path='/*' element={<PageNotFound />} />
            </Route>
          </Route>
        </Routes>
        <BottomBar PF={PF} />
      </div>
    </AuthProvider>
  );
}

export default App;