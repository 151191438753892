import { Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const DELETE_DOCUMENT_URL = '/documents/delete/';
const EDIT_DOCUMENT_URL = '/documents/edit/';
const DELETE_SHELF_URL = '/library/delete/';
const ADD_DOCUMENT_URL = '/library/adddoc/';
const VIEW_SHELF_URL = '/library/view/';
const EDIT_SHELF_URL = '/library/edit/';
const UPLOAD_FILE_URL = '/upsingle';
const DELETE_FILE_URL = '/fileDelete';

function ShelfContent({PF}) {
  let {id} = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  
  const [name, setName] = useState('');
  const [bookShelf, setBookShelf] = useState({});  
  const [description, setDescription] = useState('');
  const [editBookShelf, setEditBookShelf] = useState(false);

  const [editDocFile, setEditDocFile] = useState(false);
  const [addDocFile, setAddDocFile] = useState(false);
  const [docNumber, setDocNumber] = useState('');
  const [docDesc, setDocDesc] = useState('');
  const [docFile, setDocFile] = useState([]);

  const [selectedDocs, setSelectedDocs] = useState([]);
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_SHELF_URL + id).then((response)=>{
      if(isMounted){
        setBookShelf(response.data.shelf);
        setName(response.data.shelf.name);
        setDescription(response.data.shelf.description);
        setDocFile(response.data.documents)
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  /** Start - Shelf Functions */
  const updateShelf = async(e) => {
    e.preventDefault();
    const updatedShelf = {name, description, userId: authDecode.user.id, id};
      
    try {
      axiosPrivate.put(EDIT_SHELF_URL + id, updatedShelf).then(()=>{
        alert('Bookshelf Edited');
        setEditBookShelf(false);
        window.location.reload();
      });   
    }catch (error) {
        console.log(error);  
    }
  }
  
  const deleteShelf = async(id) => {
    try {
      axiosPrivate.delete(DELETE_SHELF_URL + id).then(()=>{
        alert('Bookshelf Deleted');
        navigate('/library');
      });               
    } catch (error) {       
    }
  }
  /** END - Shelf Functions */

  /** START - Document Functions */
  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;    
    const selectedFilesArray = Array.from(selectedFiles);
  
    const docsArray = selectedFilesArray.map((file) => {
      return ( {"file": file, "description": file.name } )
    });
    
    setSelectedDocs((previousDocs) => previousDocs.concat(docsArray));
    
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  function deleteHandler(doc) {
    setSelectedDocs(selectedDocs.filter((e) => e !== doc));   
    URL.revokeObjectURL(doc.doc);
  }

  const handleChangeInput = (id, e) => {
    const newDescription =  e.target.value
    selectedDocs.map((doc) => {
      if(doc === id){
        doc.description = newDescription;
      }
      return doc.description;
    })
  }

  const addDocuments = async(e) => {
    e.preventDefault();
    const documents = [];
    if(selectedDocs){           
      for(let i=0; i<selectedDocs.length; i++) {
        const formData = new FormData();
        const filename = Date.now()+ ' - ' + selectedDocs[i].file.name;       
        formData.append("name", filename);
        formData.append("file", selectedDocs[i].file);

        try{
          await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
        }catch (error) {
          console.log(error);  
        }

        let addedDescription = selectedDocs[i].description;
        if(!addedDescription) addedDescription = selectedDocs[i].filename.splice('.');
        const newDoc = {document: filename, description: addedDescription, libraryId: id};
        documents.push(newDoc);
      }
    }

    try {
      await axiosPrivate.post(ADD_DOCUMENT_URL + id, documents).then((response)=>{
        alert('Files Added to Shelf Successfully');
        window.location.reload();
      }).catch(function(error){
        console.log(error);
        navigate(`/library/view/${id}`);
      });
    }catch (error) {
      console.log(error);
      navigate(`/library/view/${id}`); 
    } 
  }

  const cancelAddDocFile = ()=>{
    setSelectedDocs([])
    setAddDocFile(false)
  };

  const handleEditDoc = (index, desc) => {
    setDocNumber(index)
    setEditDocFile(true)
    setDocDesc(desc)
  }

  const updateDocument = async(e, docId) => {
    e.preventDefault();
    const updatedDoc = {description: docDesc, userId: authDecode.user.id, id: docId};
      
    try {
      axiosPrivate.put(EDIT_DOCUMENT_URL + docId, updatedDoc).then(()=>{
        alert('File Detail Changed');
        setEditDocFile(false);
        window.location.reload();
      });   
    }catch (error) {
        console.log(error);  
    }
  }

  const deleteDocument = async(id, document) => {
    const fileDeleted = {document: document}
    try{
      await axiosPrivate.post(DELETE_FILE_URL, fileDeleted);   
    }catch (error) {
      console.log(error);  
    }
    
    try {
      axiosPrivate.delete(DELETE_DOCUMENT_URL + id).then(()=>{
        alert('Document Deleted');
        window.location.reload();
      });               
    } catch (error) {       
    }
  }
  /** END - Document Functions */

  const handleFileDownload = (docFile) => {
    axiosPrivate.get(PF + docFile, {responseType: 'blob'}).then((response) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(response.data);
            
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = docFile;
      alink.click();
    })
  };

  return (
    <div className="col-lg-8 mb-3">
      { editBookShelf ? (
        <div className="section-title mb-3">
          <h4 className="m-0 text-uppercase font-weight-bold">Edit {bookShelf.name}</h4>
        </div>
      ):(<div className='section-title'>
        <h4 className="m-0 font-weight-bold"><span className="text-uppercase">Library:</span> {bookShelf.name}</h4>
        {(authDecode && (authDecode?.user?.id === bookShelf.userId || authDecode?.user?.role >= 4)) && (
          <div className="singleEdit">
            <i className="singleIcon fa fa-plus mr-2" aria-hidden="true" onClick={() => setAddDocFile(true)}/>
            <i className="singleIcon far fa-edit mr-2" aria-hidden="true" onClick={() => setEditBookShelf(true)}/>
            <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteShelf(bookShelf.id)} />
          </div>
        )}                   
      </div>)}
      { editBookShelf && (
        <div className="position-relative overflow-hidden">
          <form>
            <div className="form-row">
              <div className="form-group col-md-3">Title: </div>
              <div className="form-group col-md-9">
                <input className="form-control p-2" required="required" type='text' value={name} onChange={(e)=>setName(e.target.value)} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3">Description: </div>
              <div className="col-md-9">
                <div className="form-group">                                    
                  <textarea className="form-control p-2" rows="2" value={description} onChange={(e)=>setDescription(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={updateShelf}>Update</button>
              &nbsp;&nbsp;&nbsp;  
              <button className="btn btn-primary font-weight-semi-bold px-4" onClick={()=>setEditBookShelf(false)}>Cancel</button>
            </div>
          </form> 
        </div>
      )}
      { addDocFile && 
        <div className="position-relative overflow-hidden mb-3">
          <form>
            <div className="form-group">
              {selectedDocs.length === 0 &&
                <label htmlFor="fileInput">
                  <i className="writeIcon fas fa-plus"></i> Select PDF Documents to Add
                  <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="application/pdf" />
                </label>
              }
            </div>
            <div className="form-group">
              { selectedDocs && selectedDocs.map((doc, idx) => { 
                return (
                  <div className='form-row p-2' key={idx}>
                    <div className='col-md-5'>
                      <i className='fa fa-file-pdf mr-2' style={{color: 'red'}}/>
                      {doc.file.name}
                    </div>
                    <div className='col-md-6'>
                      <input className='form-control p-4' required placeholder={doc.file.name} onChange={(e) => handleChangeInput(doc, e)} />
                    </div>
                    <div className='col-md-1'>
                      <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteHandler(doc)}/>
                    </div>
                  </div>
                );
              })}
              <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!selectedDocs } type="submit" onClick={addDocuments}>Submit</button>
              &nbsp;&nbsp;&nbsp;  
              <Link className="btn btn-primary font-weight-semi-bold px-4" to={`/library/view/${id}`} onClick={cancelAddDocFile}>Cancel</Link>  
            </div>
          </form>
        </div>
      }
      { docFile.length>0 && docFile.map((docfile, index) => {
        return(
          <div className='position-relative mb-4 pl-2' key={docfile.id}>
            {editDocFile && docNumber===docfile.id ? 
              <form>
              <div className="form-row">
                <div className="form-group col-md-3">File Name: </div>
                <div className="form-group col-md-9">
                  <input className="form-control p-2" required="required" type='text' value={docDesc} onChange={(e)=>setDocDesc(e.target.value)} />
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>updateDocument(e, docfile.id)}>Update</button>
                &nbsp;&nbsp;&nbsp;  
                <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setEditDocFile(false)}>Cancel</button>
              </div>
              </form> 
            :
              <div className='row'>
                <div className='col-lg-10'>
                  <i className='fa fa-caret-right mr-2' />
                  {docfile.description}
                </div>
                  <div className="singleEdit col-lg-2">
                    <i className='singleIcon fa fa-download mr-3' aria-hidden="true" onClick={()=>handleFileDownload(docfile.document)}/>
                    {(authDecode && (authDecode?.user?.id === bookShelf.userId || authDecode?.user?.role >= 4)) && (
                      <>
                        <i className="singleIcon far fa-edit mr-3" aria-hidden="true" onClick={() => handleEditDoc(docfile.id, docfile.description)}/>
                        <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={()=> deleteDocument(docfile.id, docfile.document)}/>
                      </>
                    )}

                    </div>
              </div>
            }
          </div>
        )           
      })}
    </div>
  )
}

export default ShelfContent