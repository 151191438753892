import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const VIEW_GALLERIES_URL = '/galleries';

function Galleries({PF}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [galleries, setGalleries] = useState([]);

  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERIES_URL).then((response) => {
      isMounted && setGalleries(response.data);
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);
 
  return (
    <div className="col-lg-8">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h4 className="m-0 text-uppercase font-weight-bold">Photo Galleries</h4>
            {(authDecode && authDecode?.user?.role >=3) && 
              <Link className="text-secondary font-weight-medium text-decoration-none" to="/gallery/add">
                <i className="writeIcon fas fa-plus"></i> Add Photo Gallery
              </Link>
            }
          </div>
        </div>
        { galleries.map((value)=>{
          return (
              <div key={value.id} className='col-lg-4 position-relative mb-3' onClick={() => navigate(`/gallery/view/${value.id}`)}>
                <img className="img-fluid w-100" src={PF + value.picture} alt="" />
                <div className="bg-white border border-top-0 p-2">
                  <div className="mb-2">
                    <Link className="text-body" to="/"><small>{new Date(value.createdAt).toDateString()}</small></Link>
                  </div>
                  <Link className="h6 d-block mb-3 text-secondary text-capitalize font-weight-bold paraLimit" to={`/gallery/view/${value.id}`}>{value.name}</Link>
                  <p className="m-0 paraLimit">{value.description}</p>
                </div>
              </div>
          );
        })}
      </div>
    </div>
  )
}

export default Galleries