import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import SocialShare from '../../Components/SocialShare';
import React, { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";

const VIEW_STORY_URL = '/news/view/';
const DELETE_STORY_URL = '/news/delete/';
const UPLOAD_FILE_URL = '/upsingle';
const EDIT_STORY_URL = '/news/edit/';

function StoryContent({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [newsStory, setNewsStory] = useState({});  
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const [postAuthor, setPostAuthor] = useState({});  
    const [postPrev, setPostPrev] = useState({});  
    const [postNext, setPostNext] = useState({});  
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_STORY_URL + id).then((response)=>{
            if(isMounted){
                setNewsStory(response.data.story);  
                setTitle(response.data.story.title);
                setDetail(response.data.story.detail);
                setPostAuthor(response.data.storyAuthor);           
                setPostPrev(response.data.prevStory);
                setPostNext(response.data.nextStory);    
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deletePost = async(id) => {
        try {
            axiosPrivate.delete(DELETE_STORY_URL + id).then(()=>{
                alert('News Story Deleted');
                navigate('/news');
            });               
        } catch (error) {    }
    };

    const onDetail = (value) => {
        setDetail(value);
    } 

    const updatePost = async(e) => {
        e.preventDefault();
        const updatedStory = {title, detail, userId: authDecode.user.id, id: newsStory.id};

        if(file){
            const formData = new FormData();
            const filename = Date.now() + file.name;
            
            formData.append("name", filename);
            formData.append("file", file);
            updatedStory.picture = filename;
      
            try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_STORY_URL + newsStory.id, updatedStory).then(()=>{
                alert('News Story Edited');
                setUpdateMode(false);
                navigate(`/news/view/${newsStory.id}`);
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    const currentPage = window.location.href;

    return (
        <div className="col-lg-8 mb-3">
            { updateMode ? (
                <div className="section-title mb-3">
                    <h4 className="m-0 text-uppercase font-weight-bold">Edit News Story</h4>
                </div>
            ):(<>
                <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">{newsStory?.title}</h2>
                {authDecode && (authDecode?.user?.id === newsStory.userId || authDecode?.user?.role >= 4) ? (
                    <div className="singleEdit">
                        <i className="singleIcon far fa-edit mr-2" aria-hidden="true" onClick={() => setUpdateMode(true)}/>
                        <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deletePost(newsStory.id)} />
                    </div>
                ):(<></>)}
                <div className="mb-3">
                    <span className="singleAuthor">By: <Link to={`/user/view/${newsStory.userId}`}>{postAuthor?.name}</Link></span>
                    <span className="singleDate"> -- {new Date(newsStory?.createdAt).toDateString()}</span>
                </div>                    
            </>)}
            <SocialShare currentPage={currentPage} title={newsStory?.title}/>
            <div className="bg-white border border-top-0">
                { file ? (
                    <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                ): (
                    newsStory?.picture && <img src={PF + newsStory.picture} alt="" className="img-fluid w-100" style={{height: '500px', objectFit: 'cover'}} />
                )}
                { updateMode ? 
                    <form className="p-2">
                        <div className="form-group">
                            <label htmlFor="fileInput">
                                Change Image: <i className="writeIcon fas fa-recycle" />
                            </label>
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                        </div>
                        <div className="form-group">
                            Title: 
                            <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                        </div>
                        <div className="form-group">
                            Story Details:
                            <EditorToolbar toolbarId={'t1'} />
                            <ReactQuill
                                theme="snow" rows="10" modules={modules('t1')} formats={formats}
                                value={detail}
                                onChange={onDetail}
                            />
                        </div>
                        <button type="submit"className="btn btn-primary font-weight-semi-bold px-4" onClick={updatePost}>Update</button>
                        &nbsp;&nbsp;&nbsp;  
                        <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                    </form> 
                    : (<div className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: newsStory.detail}} style={{whiteSpace: "pre-line"}} />)
                }
                <div className='row p-2'>
                    {postNext?.picture &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/news/view/${postNext.id}`)}>
                            <img className="img-fluid h-100" src={PF + postNext.picture} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">                                                
                                <div className="mb-2">
                                    <Link className="text-white" to="/"><small>{new Date(postNext.createdAt).toDateString()}</small></Link>
                                </div>
                                Next: <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/news/view/${postNext.id}`}>{postNext.title}</Link>
                            </div>
                        </div>
                    }
                    {postPrev?.picture &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/news/view/${postPrev.id}`)}>
                            <img className="img-fluid h-100" src={PF + postPrev.picture} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                <div className="mb-2">
                                    <Link className="text-white" to="/"><small>{new Date(postPrev.createdAt).toDateString()}</small></Link>
                                </div>
                                Previous: <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/news/view/${postPrev.id}`}>{postPrev.title}</Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StoryContent