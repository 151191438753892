import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';

const VIEW_ARTICLES_URL = '/articles';

function ArticlesContent({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const [allArticles, setAllArticles] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ARTICLES_URL).then((response) => {
            isMounted && setAllArticles(response.data);
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort(); }
        // eslint-disable-next-line
    }, []);

  return (
    <div className="col-lg-8 mb-3">
        <div className="row">
            <div className="col-12">
                <div className="section-title">
                    <h4 className="m-0 text-uppercase font-weight-bold">More Articles</h4>
                </div>
            </div>
            {allArticles.map((value)=>{
                return (
                    <div className="col-lg-4" key={value.id}>
                        <div className='position-relative mb-3' onClick={() => navigate(`/articles/view/${value.id}`)}>
                            <img className="img-fluid w-100" src={PF + value.picture} alt="" />
                            <div className="bg-white border border-top-0 p-2">
                                <div className="mb-2">
                                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" to="/">{value.username}</Link>
                                    <small className="text-body">{new Date(value.createdAt).toDateString()}</small>
                                </div>
                                <Link className="h6 d-block mb-3 text-secondary text-uppercase font-weight-bold paraLimit" to={`/articles/view/${value.id}`}>{value.title}</Link>
                                <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.detail}} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    </div>
  )
}

export default ArticlesContent
