import { Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode'

const VIEW_EVENTS_URL = '/events/view/';
const UPLOAD_FILE_URL = '/upsingle';
const EDIT_EVENT_URL = '/events/edit/';

function EditEvent({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [calEvent, setCalEvent] = useState({});  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  let id = useParams();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_EVENTS_URL + id.id).then((response)=>{
      if(isMounted){
        setCalEvent(response.data)
        setTitle(response.data.title);
        setLocation(response.data.location);
        setEventDate(response.data.date);
        setDescription(response.data.description);
      }
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  const updateCalEvent = async(e) => {
      e.preventDefault();
      const date = eventDate;
      const updatedEvent = {title, date, location, description, UserId: authDecode.user.id, };
        
      if(file){
        const formData = new FormData();
        const filename = Date.now() + '--' + file.name;
            
        formData.append("name", filename);
        formData.append("file", file);
        updatedEvent.picture = filename;
      
        try{
          await axiosPrivate.post(UPLOAD_FILE_URL, formData);
        }catch(err){
          console.log(err);
        }
      }

      try {
        axiosPrivate.put(EDIT_EVENT_URL + calEvent.id, updatedEvent).then(()=>{
          alert('Calendar Event Edited');
          navigate('/events');
        }).catch(function(error){
            console.log(error.config);
        });   
      }catch (error) {
          console.log(error);  
      }
  }

  return (
    <div className="col-lg-8 mb-3">
      <div className="section-title mb-3">
        <h4 className="m-0 text-uppercase font-weight-bold">Edit Event</h4>
      </div>
      { file ? (
        <img className="img-fluid" src={URL.createObjectURL(file)} alt="" />
      ): (
        <img src={PF + calEvent.picture} alt="" className="img-fluid" />
      )}
      <form>
        <div className='form-group'>
          <label htmlFor='fileInput'>Change Image: <i className="writeIcon fas fa-recycle"></i> </label>
          <input type='file' id='fileInput' style={{display: 'none'}} onChange={(e)=>setFile(e.target.files[0])} />
        </div>
        <div className="form-group">
          <input type='text' value={title} className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
        </div>
        <div className="form-group">
          <input value={eventDate} type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
        </div>                
        <div className="form-group">
          <input value={location} type='text' placeholder='' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
        </div>
        <div className="form-group">
          <textarea value={description} type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
        </div>
        <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!title || !eventDate || !location || !description} type="submit" onClick={(e)=>updateCalEvent(e)}>Submit</button>
        &nbsp;&nbsp;&nbsp;  
        <Link className="btn btn-primary font-weight-semi-bold px-4" to={`/events`}>Cancel</Link>
      </form>
    </div>
  )
}

export default EditEvent
